import {React, useEffect, useRef, useState} from "react";
import Modal from "react-modal";
import {customStyles, DATE_RANGE, PRIORITY, } from "../../Constants";
import './modalStyles.css';
import {useForm} from "react-hook-form";
import {fetchSellerRequest, updateSellerList} from "../../Functions/SellersList";

export default function UpdateSellerRequestPriority({showModal, setShowModal, sellerRequestId, setUpdateSellerList}) {
    const { register, handleSubmit , setValue} = useForm();
    const[,setFilterOpen] = useState();
    const[sellerRequest, setSellerRequest] = useState([]);

    useEffect( () => fetchSellerRequest(sellerRequestId, setSellerRequest, setFilterOpen), [sellerRequestId])
    return <>
        <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            style={customStyles}
            contentLabel="Responses"
            ariaHideApp={false}
        >

            <h2>Adjust Priority</h2>
            <button className="grayButton" onClick={() => setShowModal(false)}>Close</button>
            {sellerRequest && <>
                    <table className='sellerListTable'>
                        <thead>
                            <tr className="outline">
                                <th className="outline">Phase</th>
                                <th className="outline">Priority</th>
                                <th className="outline">Requested On</th>
                                <th className="outline">Seller Req ID</th>
                                <th className="outline">Category</th>
                                <th className="outline">Request Name</th>
                                <th className="outline">Request Details</th>
                                <th className="outline">{DATE_RANGE}</th>
                                <th className="outline">Preferred File Type</th>
                                <th className="outline">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key={sellerRequest.id} className="outline">
                                <td className="outline">{sellerRequest.batch}</td>
                                <td className="outline">{PRIORITY[sellerRequest.priority]}</td>
                                <td className="outline">{sellerRequest.assigned_date}</td>
                                <td className="outline">{sellerRequest.seller_request_id}</td>
                                <td className="outline">{sellerRequest.category}</td>
                                <td className="outline">{sellerRequest.data_requested}</td>
                                <td className="outline">{sellerRequest.details}</td>
                                <td className="outline">{sellerRequest.parameters}</td>
                                <td className="outline">{sellerRequest.file_format}</td>
                                <td className="outline">{sellerRequest.status}</td>
                            </tr>
                        </tbody>
                    </table>
                <br/>
                <form onSubmit={handleSubmit(data =>
                    updateSellerList(sellerRequestId,data, setUpdateSellerList, setShowModal))
                }>
                    <label>Priority</label>
                    {setValue('priority', sellerRequest.priority)}
                    <select {...register('priority')}>
                        <option value={0}>Select Priority</option>
                        <option value={1}>High</option>
                        <option value={2}>Medium</option>
                        <option value={3}>Low</option>
                    </select>

                    <input type="submit" value="Update Priority" className="saveNewSellerRequestButton"/>
                </form>
            </>}
        </Modal>
    </>
}