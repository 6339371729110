import {React, useEffect, useRef, useState} from "react";
import Modal from "react-modal";
import {customStyles, DATE_RANGE, RESPOND_BUTTON_TEXT} from "../../Constants";
import DeleteConfirmation from "../../components/Modals/DeleteConfirmation";
import {deleteFiles, downloadFile} from "../../Functions/Files";
import './modalStyles.css';
import {useForm} from "react-hook-form";
import {calculate_date_time} from "../../Functions/DateTimeFunctions";

export default function ResponseViewModal({ResponseViewModalOpen, setOpenViewResponseModal, sellerResponseViewData,
                                          openResponseModal, sellerRequest}) {
    const { register, handleSubmit , reset} = useForm();
    const [isDownloading, setIsDownloading] = useState(false);
    const[deleteConfirmationModalOpen, setdeleteConfirmationModalOpen] = useState();

    const [popup, setPopup] = useState({
        show: false, // initial values set to false and null
        id: null,
    });

    const handleDeleteTrue = () => {
        if (popup.show && popup.id) {
            deleteFiles(popup.id);
            setPopup({
                show: false,
                id: null,
            });
        }
    };

    const handleDeleteFalse = () => {
        setPopup({
            show: false,
            id: null,
        });
    };

    return <>
        {popup.show && (
            <DeleteConfirmation
                handleDeleteTrue={handleDeleteTrue}
                handleDeleteFalse={handleDeleteFalse}
                deleteConfirmationModalOpen={deleteConfirmationModalOpen}
                setdeleteConfirmationModalOpen={setdeleteConfirmationModalOpen}
            />
        )}
        <Modal
            isOpen={ResponseViewModalOpen}
            onRequestClose={() => setOpenViewResponseModal(false)}
            style={customStyles}
            contentLabel="Responses"
            ariaHideApp={false}
        >
            <h2>Responses</h2>

            {sellerRequest &&
                <>
                    <table className='sellerListTable'>
                        <thead>
                        <tr className="outline">
                            <th className="outline">Phase</th>
                            <th className="outline">Requested On</th>
                            <th className="outline">Seller Req ID</th>
                            <th className="outline">Category</th>
                            <th className="outline">Request Name</th>
                            <th className="outline">Request Details</th>
                            <th className="outline">{DATE_RANGE}</th>
                            <th className="outline">Preferred File Type</th>
                            <th className="outline">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr key={sellerRequest.id} className="outline">
                            <td className="outline">{sellerRequest.batch}</td>
                            <td className="outline">{sellerRequest.assigned_date}</td>
                            <td className="outline">{sellerRequest.seller_request_id}</td>
                            <td className="outline">{sellerRequest.category}</td>
                            <td className="outline">{sellerRequest.data_requested}</td>
                            <td className="outline">{sellerRequest.details}</td>
                            <td className="outline">{sellerRequest.parameters}</td>
                            <td className="outline">{sellerRequest.file_format}</td>
                            <td className="outline">{sellerRequest.status}</td>
                        </tr>
                        </tbody>
                    </table>
                </>
            }
            <br/>
            <button className="blueButton" onClick={()=> { openResponseModal(sellerRequest.id, "POST") }}>{RESPOND_BUTTON_TEXT}</button>
            <button className="grayButton" onClick={() => setOpenViewResponseModal(false)}>Close</button>
            <table className='sellerListTable'>
                <thead>
                <tr>
                    <th className="outline">Response ID</th>
                    <th className="outline">Uploaded Date</th>
                    <th className="outline">Uploaded By</th>
                    <th className="outline">Comments</th>
                    <th className="outline">Last User Download</th>
                    <th className="outline">Last Company Download</th>
                    <th className="outline">Document</th>
                </tr>
                </thead>
                <tbody>
                {sellerResponseViewData && sellerResponseViewData.map((sellerResponses,i)=>{
                    const documents = sellerResponses.docstorage;
                    let last_user_download = '';
                    let last_entity_download = '';
                    let time_created = '';
                    if (sellerResponses.last_user_download !== '') { last_user_download = calculate_date_time(sellerResponses.last_user_download); }
                    if (sellerResponses.last_entity_download !== '') { last_entity_download = calculate_date_time(sellerResponses.last_entity_download); }
                    if (sellerResponses.time_created !== '') { time_created = calculate_date_time(sellerResponses.time_created); }

                    return (
                        <>
                            <tr key={sellerResponses.id}>
                                <td className="outline">{sellerResponses.seller_response_id}</td>
                                <td className="outline">{time_created}</td>
                                <td className="outline">{sellerResponses.user}</td>
                                <td className="outline">{sellerResponses.response_details}</td>
                                <td className="outline">{last_user_download}</td>
                                <td className="outline">{last_entity_download}</td>
                                <td className="outline">
                                    {documents && documents.map((document, )=>{
                                        const rand = Boolean(Math.round(Math.random()));
                                        const formStyle = {
                                            display: "contents"
                                        }
                                        return (<>
                                                <form onSubmit={handleSubmit(data => downloadFile(document.blob_name_raw, document.blob_name))}
                                                      style={formStyle}>
                                                    <input type="submit" value={document.blob_name} className="grayButton"/>
                                                </form>
                                        </>)
                                    })
                                    }
                                </td>

                            </tr>
                        </>
                    )
                })}
                </tbody>
            </table>
        </Modal>
    </>
}