import './App.css';
import React, {useState} from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navigation from "./layout/Navigation/Navigation";
import Login from "./components/Login/Login";

function App() {
    const [token, setToken] = useState(localStorage.getItem("accessToken"));

    if(!token) {
        return <Login setToken={setToken} />
    }

  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<Navigation />} />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;