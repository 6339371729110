import {React, useCallback, useEffect, useRef, useState,} from "react";
import axios from "axios";
import Modal from 'react-modal';
import { useForm } from "react-hook-form";
import './dataRequestmanagement.css';
import { BUYER_PREFERRED_FILE_TYPE, customStyles, DATE_RANGE, PRIORITY, RESPOND_BUTTON_TEXT
} from "../../Constants";
import {fetchResponse} from "../../Functions/Responses";
import ResponseViewModal from "../../components/Modals/ResponseViewModal";
import {fetchBuyersList} from "../../Functions/BuyersList";
import ImportSpreadsheetModal from "../../components/Modals/ImportSpreadsheetModal";
import fetchCategories from "../../Functions/CategoryFunctions";
import {fetchFileTypes} from "../../Functions/Files";
import {fetchSellerList, saveNewSellerData, showEditModal, updateSellerList} from "../../Functions/SellersList";
import {fetchStatuses} from "../../Functions/StatusFunction";
import SellerListFilter from "../../components/Filters/SellerFilter";
import useLocalStorageInt from "../../Functions/useLocalStorageInt";
import AddResponseModal from "../../components/Modals/AddResponseModal";
import UpdateSellerRequestPriority from "../../components/Modals/UpdateSellerRequestPriority";
import {SortingOption} from "../../components/Sorting/Sorting";

export default function DataRequestManagement() {
    const { register, handleSubmit , reset, setValue} = useForm();
    const { register:registerNewSellerData, handleSubmit:handleSubmitNewSellerData, reset:resetNewSellerData, setValue:setSellerValue} = useForm();
    const { register: registerUpdate, handleSubmit: handleSubmitUpdate , reset:resetUpdate} = useForm();
    const[updateSellerListData,setUpdateSellerListData] = useState(false);
    const[documentsUploading, setDocumentsUploading] = useLocalStorageInt("documentsUploading", localStorage.getItem("documentsUploading"));
    const[buyerRowData, setBuyerRowData] = useState([]);
    const[newSellerRequestError, setNewSellerRequestError] = useState();
    const[buyerFilteredData, setBuyerFilteredData] = useState({
        buyer_request_list_id: "",
        category: "",
        date_range: "",
        details: "",
        entity_id: "",
        last_response: "",
        linkedRequests: "unlinked",
        phase: "",
        priority: "",
        requestId: "",
        request_name: "",
        seller_request_id: "",
        seller_request_status: "",
        status_id:"",
    });
    const[buyerFiltered, setBuyerFiltered] = useState(false);
    const[buyerSorted, setBuyerSorted] = useState(false);
    const[sellerFilteredData, setSellerFilteredData] = useState({
        id: "",
        assigned_date: "",
        seller_request_id: "",
        deliverable_phase: "",
        priority: "",
        data_requested: "",
        parameters: "",
        details: "",
        description: "",
        status_id: "",
        batch: "",
        category_id: "",
        batch_enabled: false,
        deleted: false,

    });
    const[sellerFiltered, setSellerFiltered] = useState(false);
    const[sellerSorted, setSellerSorted] = useState(false);
    const[clearData, setClearData] = useState();
    const[dataRequest, setDataRequest] = useState([]);
    const[errorMessage, setErrorMessage] = useState();
    const[importBuyerRequestModalOpen, setImportBuyerRequestModalOpen] = useState(false);
    const[linkConfirmationModalStatus, setLinkConfirmationModalStatus] = useState(false);
    const[modalIsOpen, setIsOpen] = useState(false);
    const[newRowToLink, setNewRowToLink] = useState();
    const[notification, setNotification] = useState();
    const[sellerRequest, setSellerRequest] = useState();
    const[sellerRequestId, setSellerRequestId] = useState();
    const[successMessage, setSuccessMessage] = useState();
    const[sellerFilterOpen, setSellerFilterOpen] = useState(false);
    const[sellerSortOpen, setSellerSortOpen] = useState(false);
    const[sellerDataRequested, setSellerDataRequested] = useState("");
    const[sellerRequestParameters, setSellerRequestParameters] = useState("");
    const[sellerRequestDetails, setSellerRequestDetails] = useState("");
    const[sellerRequestFileType, setSellerRequestFileType] = useState("");
    const[, setSellerCategory] = useState("");
    const[action, setAction] = useState();
    const[buyerFilterOpen, setBuyerFilterOpen] = useState(false);
    const[sellerListRowData, setsellerListRowData] = useState([]);
    const[boxes, setBoxes] = useState({});
    const[disableCheckboxes, setDisableCheckboxes] = useState(false);
    const[editResponseData, setEditResponseData] = useState([]);
    const[error, setError] = useState();
    const[responseError, setResponseError] = useState();
    const[files, setFiles] = useState([]);
    const[filterOpen, setFilterOpen] = useState(false);
    const[isLoading, setIsLoading] = useState(false);
    const[ResponseModalOpen, setOpenResponseModal] = useState(false);
    const[ResponseViewModalOpen, setOpenViewResponseModal] = useState(false);
    const[sellerResponseRequestId, setSellerResponseRequestId] = useState('');
    const[sellerResponseViewData, setSellerResponseViewData] = useState([]);
    const[sellerReponseId, setSellerResponseId] = useState();
    const[buyerSortOpen, setBuyerSortOpen] = useState();
    const[statuses, setStatuses] = useState();
    const[categories, setCategories] = useState();
    const[fileTypes, setFileTypes] = useState();
    const[buyerListSorting, setBuyerListSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
        sort_five: "",
        sort_five_direction:"asc",
        sort_six: "",
        sort_six_direction:"asc",
        sort_seven: "",
        sort_seven_direction:"asc",
        sort_eight: "",
        sort_eight_direction:"asc",
        sort_nine: "",
        sort_nine_direction:"asc",
        sort_ten: "",
        sort_ten_direction:"asc",
        sort_eleven: "",
        sort_eleven_direction:"asc",
        sort_twelve: "",
        sort_twelve_direction:"asc",
    });
    const buyer_sort_array = [
        {id: "seller_request_list.seller_request_id", name:"Seller Request ID"},
        {id: "buyer_request_list_id", name: "Buyer Request ID"},
        {id: "priority", name:"Priority"},
        {id: "category", name:"Category"},
        {id: "request_name", name:"Request Name"},
        {id: "buyer_request_list.details", name:"Request Details"},
        {id: "date_range", name:DATE_RANGE},
        {id: "file_type.file_type", name:"Preferred File Type"},
    ];
    const[sellerListSorting, setSellerListSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
        sort_five: "",
        sort_five_direction:"asc",
        sort_six: "",
        sort_six_direction:"asc",
        sort_seven: "",
        sort_seven_direction:"asc",
        sort_eight: "",
        sort_eight_direction:"asc",
        sort_nine: "",
        sort_nine_direction:"asc",
        sort_ten: "",
        sort_ten_direction:"asc",
    });
    const seller_sort_array = [
        {id: "batch", name: "Phase"},
        {id: "priority", name: "Priority"},
        {id: "assigned_date", name: "Requested On"},
        {id: "seller_request_id", name: "Seller Req ID"},
        {id: "category.category_name", name: "Category"},
        {id: "data_requested", name: "Request Name"},
        {id: "details", name: "Request Details"},
        {id: "parameters", name: DATE_RANGE},
        {id: "status.status", name: "Status"},
    ];
    const[updateBuyerList, setUpdateBuyerList] = useState();
    const[updatePriorityModal, showUpdatePriorityModal] = useState(false);
    const token = localStorage.getItem("accessToken");
    const projectId = localStorage.getItem('projectId');
    const buyerFilterRef = useRef(null);
    const buyerSortRef = useRef(null);
    const sellerFilterRef = useRef(null);
    const sellerSortRef = useRef(null);

    const handleClickOutside = event => {
        if (buyerFilterRef.current && !buyerFilterRef.current.contains(event.target)) {
            // Close the modal here
            setBuyerFilterOpen(false);
        }
        if (buyerSortRef.current && !buyerSortRef.current.contains(event.target)) {
            // Close the modal here
            setBuyerSortOpen(false);
        }
        if (sellerFilterRef.current && !sellerFilterRef.current.contains(event.target)) {
            // Close the modal here
            setSellerFilterOpen(false);
        }
        if (sellerSortRef.current && !sellerSortRef.current.contains(event.target)) {
            // Close the modal here
            setSellerSortOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleBuyersFetch = (e) => {
        for (const key in buyerFilteredData) {
            if (buyerFilteredData[key] !== "") {
                setBuyerFiltered(true);
                break;
            }
        }

        for (const key in buyerListSorting) {
            if (buyerListSorting[key] !== "" && buyerListSorting[key] !== "asc") {
                setBuyerSorted(true);
                break;
            }
        }

        fetchBuyersList(projectId, buyerFilteredData, setBuyerRowData, setBuyerFilterOpen, buyerListSorting, setBuyerSortOpen);
        setUpdateBuyerList(false);
    }
    
    const handleSellersFetch = (e) => {
        setError(null);
        for (const key in sellerFilteredData) {
            if (sellerFilteredData[key] !== "") {
                setSellerFiltered(true);
                break;
            }
        }

        for (const key in sellerListSorting) {
            if (sellerListSorting[key] !== "" && sellerListSorting[key] !== "asc") {
                setSellerSorted(true);
                break;
            }
        }

        fetchSellerList(projectId, sellerFilteredData, setsellerListRowData, setSellerFilterOpen, sellerListSorting, setSellerSortOpen);
        setUpdateSellerListData(false);
    };

    useEffect(() => fetchCategories(setCategories), []);
    useEffect(() => fetchFileTypes(setFileTypes), []);
    useEffect(() => fetchStatuses(setStatuses), []);

    function openResponseModal(seller_request_id, seller_response_id, action, data) {
        reset();
        resetUpdate();
        if (action === "PUT") {
            setAction("PUT");
            fetchResponse(seller_response_id, resetUpdate, setEditResponseData, setResponseError);
        } else {
            setAction("POST");
        }
        setSellerRequest(data);
        setOpenViewResponseModal(false);
        setSellerResponseRequestId(seller_request_id);
        setSellerResponseId(seller_response_id);
        setOpenResponseModal(!ResponseModalOpen);
    }

    function clearBuyerFilter() {
        const emptyFilterData = Object.keys(buyerFilteredData).reduce((acc, key) => {
            acc[key] = "";
            return acc;
        }, {});
        setBuyerFilteredData(emptyFilterData);
        handleBuyersFetch();
        setUpdateBuyerList(true);
        setBuyerFiltered(false);
    }

    function clearBuyerSort() {
        const emptySortData = Object.keys(buyerListSorting).reduce((acc, key) => {
            if (key.endsWith('_direction')) {
                acc[key] = 'asc';
            } else {
                acc[key] = '';
            }
            return acc;
        }, {});
        setBuyerListSorting(emptySortData);
        handleBuyersFetch();
        setUpdateBuyerList(true);
        setBuyerSorted(false);
    }

    function clearSellerFilter() {
        const initialFilterData = sellerFilteredData;
        const emptyFilterData = Object.keys(initialFilterData).reduce((acc, key) => {
            acc[key] = "";
            return acc;
        }, {});

        setSellerFilteredData(emptyFilterData);
        handleSellersFetch();
        setUpdateSellerListData(true);
        setSellerFiltered(false);
    }

    function clearSellerSort() {
        const emptySortData = Object.keys(sellerListSorting).reduce((acc, key) => {
            if (key.endsWith('_direction')) {
                acc[key] = 'asc';
            } else {
                acc[key] = '';
            }
            return acc;
        }, {});
        setSellerListSorting(emptySortData);
        handleSellersFetch();
        setUpdateSellerListData(true);
        setSellerSorted(false);
    }

    useEffect(() => handleBuyersFetch(), [updateBuyerList]);
    useEffect(() => handleSellersFetch(), [updateSellerListData]);

    function handleChange(e) {
        const {
            target: { id, checked }
        } = e;
        setBoxes({ ...boxes, [id]: checked });
    }

    useEffect(()=>{
        setSuccessMessage();
        setClearData(false);
    }, [clearData]);

    function callAdjustPriority(seller_list_id) {
        setSellerRequestId(seller_list_id);
        showUpdatePriorityModal(true);
    }

    function saveLink() {
        if (dataRequest.length === 0 || !sellerRequestId) {
            setErrorMessage('You need to select both a Data Row and Seller Row');
            return;
        }

        dataRequest.forEach((value) => {
            putBuyersRequest(value, sellerRequestId);
            setClearData(true);
        })
        handleBuyersFetch();
        setLinkConfirmationModalStatus(false);
    }

    function putBuyersRequest(id, sellerId) {
        axios.put(process.env.REACT_APP_API_BASE_URL + '/api/v1/buyer_request_list/'+id,
            {'project_id':projectId, 'seller_request_id': sellerId},
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                handleBuyersFetch();
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }

    function createNewSellerRequest(rowId) {
        resetNewSellerData();
        reset();
        setSellerDataRequested("");
        setSellerRequestParameters("");
        setSellerRequestDetails("");
        setSellerCategory("");
        setSellerRequestFileType("");
        setNewRowToLink(rowId);

        //Setting the modal with the existing content
        let filteredDmoData = buyerRowData.filter(dmo => dmo['id'] === rowId);
        setSellerDataRequested(filteredDmoData[0]['request_name']);
        setSellerRequestParameters(filteredDmoData[0]['date_range']);
        setSellerRequestDetails(filteredDmoData[0]['details']);
        setSellerCategory(filteredDmoData[0]['category']);
        setSellerRequestFileType(filteredDmoData[0]['file_type_id']);

        setIsOpen(true);
        setAction("POST");
        setClearData(true);
    }

    function closeModal() {
        setSellerDataRequested("");
        setSellerRequestParameters("");
        setSellerRequestDetails("");
        setSellerCategory("");
        setNewSellerRequestError(false);
        setIsOpen(false);
    }

    function callUpdateSellerList(seller_list_id) {
        const status = statuses.filter(status => status['status'] === "Open");
        const data = {"status_id": status[0].id};
        updateSellerList(seller_list_id, data, setUpdateSellerListData);
    }

    function updateSellerRequest(seller_request) {
        setIsOpen(true);
        setAction("PUT");
        setSellerRequest(seller_request);
    }

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="New Seller Request"
                ariaHideApp={false}
            >
                {action == "PUT" && <h2>Update Seller Request</h2>}
                {action == "POST" && <h2>New Seller Request</h2>}
                {newSellerRequestError && <h1 className="userError">{newSellerRequestError}</h1>}
                <button className="grayButton" onClick={closeModal}>Close</button>
                <form onSubmit={handleSubmitNewSellerData(data => saveNewSellerData(data,
                    setNewSellerRequestError, putBuyersRequest, setIsOpen, setUpdateSellerListData, action))}
                >
                    {action == "POST" && <input name="rowToLink" defaultValue={newRowToLink} {...registerNewSellerData('rowToLink')} hidden/> }

                    {action === "PUT" && sellerRequest && setSellerValue("id", sellerRequest.id)}
                    {action == "PUT" && <input name="id" {...registerNewSellerData('id')} hidden/> }
                    <label>Data Requested</label>
                    {action === "PUT" && sellerRequest && setSellerValue("data_requested", sellerRequest.data_requested)}
                    <input name="data_requested" defaultValue={sellerDataRequested} {...registerNewSellerData('data_requested')} />

                    <label>Priority</label>
                    {action == "PUT" && setSellerValue("priority", sellerRequest.priority)}
                    <select {...registerNewSellerData('priority')}>
                        <option value={0}>Select Priority</option>
                        <option value={1}>High</option>
                        <option value={2}>Medium</option>
                        <option value={3}>Low</option>
                    </select>

                    <label>Dates Requested</label>
                    {action === "PUT" && sellerRequest && setSellerValue("parameters", sellerRequest.parameters)}
                    <input name="sellerRequestParameters" defaultValue={sellerRequestParameters} {...registerNewSellerData('parameters')} />

                    <label>Request Details</label>
                    {action === "PUT" && sellerRequest && setSellerValue("details", sellerRequest.details)}
                    <textarea rows={10} name="sellerRequestDetails" defaultValue={sellerRequestDetails} {...registerNewSellerData('details')} />

                    <label>Phase</label>
                    {action === "PUT" && sellerRequest && setSellerValue("batch", sellerRequest.batch)}
                    <input name="sellerBatch" {...registerNewSellerData('batch')} />

                    <label>Category</label>
                    {action === "PUT" && sellerRequest && setSellerValue("category_id", sellerRequest.category_id)}
                    <select {...registerNewSellerData('category_id')}>
                        <option key={0} value={0}>Select a Category</option>
                        {categories && categories.map((category,i)=> {
                            return (
                                <option key={category.id} value={category.id}>{category.category_name}</option>
                            )
                        })}
                    </select>

                    <label>File Type</label>
                    {action === "PUT" && sellerRequest && setSellerValue("file_type_id", sellerRequest.file_type_id)}
                    <select {...registerNewSellerData('file_type_id')} >
                        <option key={0} value={0}>Select a File Type</option>
                        {fileTypes && fileTypes.map((fileType,i)=> {
                            return (
                                <option key={fileType.id} value={fileType.id}>{fileType.file_type}</option>
                            )
                        })}
                    </select>


                    <label style={{ marginTop: '10px' }}>Notify Immediately</label>
                    <input type="checkbox"
                           style={{ marginTop: '-25px' }}
                           {...registerNewSellerData('notify_immediately')}/>

                    <input type="submit" value="Save New Seller Request" className="saveNewSellerRequestButton"/>
                </form>
            </Modal>
            <ImportSpreadsheetModal
                importBuyerRequestModalOpen={importBuyerRequestModalOpen}
                setImportBuyerRequestModalOpen={setImportBuyerRequestModalOpen}
                handleBuyersFetchClick={handleBuyersFetch}
                buyerFilteredData={buyerFilteredData}
                setNotification={setNotification}
                setError={setErrorMessage}
            />

            {projectId && <h2><div style={{color:"#3178C6"}}>Project {localStorage.getItem('projectname')}</div><div style={{color:"gray"}}>Request Management</div></h2>}
            {!projectId && <div>
                <h2><div style={{color:"red"}}>Project Not Selected</div>
                    <div style={{color:"gray"}}>Request Management</div>
                </h2>
                <em style={{color:"red"}}>Please select a project on the Home page</em>
            </div>}
            {notification && <h1 className="blackNotification">{notification }</h1>}
            {errorMessage && <h1 className="userError">{errorMessage}</h1> }
            {successMessage && <div className="DmoSuccess">{successMessage}</div> }
            <AddResponseModal
                ResponseModalOpen={ResponseModalOpen}
                setOpenResponseModal={setOpenResponseModal}
                setFiles={setFiles}
                sellerRequest={sellerRequest}
                sellerResponseRequestId={sellerResponseRequestId}
                files={files}
                setUpdateList={setUpdateSellerListData}
            />

            <Modal
                isOpen={linkConfirmationModalStatus}
                onRequestClose={setLinkConfirmationModalStatus}
                style={customStyles}
                ariaHideApp={false}
            >
                <h1>Confirm Link </h1>
                <button onClick={()=>saveLink()}>Save</button>
                <button onClick={()=>setLinkConfirmationModalStatus(false)}>Cancel</button>
            </Modal>

            <ResponseViewModal
                ResponseViewModalOpen={ResponseViewModalOpen}
                setOpenViewResponseModal={setOpenViewResponseModal}
                sellerResponseViewData={sellerResponseViewData}
                openResponseModal={openResponseModal}
                sellerRequest={sellerRequest}
            />

            {projectId && <>
                <h1>Buyer Request List</h1>
                <div>
                    <button className="grayButton" onClick={() => setBuyerFilterOpen(!buyerFilterOpen)}>Filter</button>
                    {buyerFiltered && <button className="grayButton" onClick={()=>clearBuyerFilter()}>Clear Filter</button>}
                    {!buyerFiltered && <button className="lightGrayButton" onClick={()=>clearBuyerFilter()}>Clear Filter</button>}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <button className="grayButton" onClick={() => setBuyerSortOpen(!buyerSortOpen)}>Sort</button>
                    {buyerSorted && <button className="grayButton" onClick={()=> clearBuyerSort()}>Clear Sort</button>}
                    {!buyerSorted && <button className="lightGrayButton" onClick={()=> clearBuyerSort()}>Clear Sort</button>}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    {projectId && <button className="blueButton" onClick={() => setImportBuyerRequestModalOpen(true)} >
                        Import Buyer's Requests
                    </button>}
                    {buyerFilterOpen ? (
                        <ul className="menu thirtyFourPercentMenu" ref={buyerFilterRef}>
                            <li className="menu-item">
                                Linked <br />
                                <select name="filterlinkedRequests" defaultValue={buyerFilteredData.linkedRequests} onChange={(e) =>
                                    setBuyerFilteredData( {...buyerFilteredData, linkedRequests: e.target.value})}>
                                    <option value="all">All</option>
                                    <option value="unlinked">Unlinked</option>
                                    <option value="linked">Linked</option>
                                </select>
                            </li>
                            <li className="menu-item">
                                Seller Req ID
                                <input type="text" defaultValue={buyerFilteredData.seller_request_id} name="filterRequestId" placeholder="Seller Req ID"
                                       onChange={(e) =>
                                           setBuyerFilteredData( {...buyerFilteredData, seller_request_id: e.target.value})}>
                                </input>
                            </li>
                            <li className="menu-item">
                                Buyer Req ID
                                <input type="text" defaultValue={buyerFilteredData.buyer_request_list_id} name="filterRequestId" placeholder="Buyer Req ID"
                                       onChange={(e) =>
                                           setBuyerFilteredData( {...buyerFilteredData, buyer_request_list_id: e.target.value})}>
                                </input>
                            </li>

                            <li className="menu-item">
                                Priority <br />
                                <select  name="filterPriority" defaultValue={buyerFilteredData.priority}
                                         onChange={(e) =>
                                             setBuyerFilteredData( {...buyerFilteredData, priority: e.target.value})}>
                                    <option value="">Filter By Priority</option>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                </select>
                            </li>
                            <li className="menu-item">
                                Category
                                <input type="text" name="filterCategory" defaultValue={buyerFilteredData.category} placeholder="Category"
                                       onChange={(e) =>
                                           setBuyerFilteredData( {...buyerFilteredData, category: e.target.value})}>
                                </input>
                            </li>
                            <li className="menu-item">
                                Request Name
                                <input type="text" name="filterRequestName" defaultValue={buyerFilteredData.request_name} placeholder="Request Name"
                                       onChange={(e) =>
                                           setBuyerFilteredData( {...buyerFilteredData, request_name: e.target.value})}>
                                </input>
                            </li>
                            <li className="menu-item">
                                Request Details
                                <input type="text" defaultValue={buyerFilteredData.details} name="filterRequestId" placeholder="Details"
                                       onChange={(e) =>
                                           setBuyerFilteredData( {...buyerFilteredData, details: e.target.value})}>
                                </input>
                            </li>
                            <li className="menu-item">
                                {DATE_RANGE}
                                <input type="text" defaultValue={buyerFilteredData.date_range} name="filterRequestId" placeholder={DATE_RANGE}
                                       onChange={(e) =>
                                           setBuyerFilteredData( {...buyerFilteredData, date_range: e.target.value})}>
                                </input>
                            </li>
                            <li>
                                <button onClick={() => handleBuyersFetch()}>Apply</button>
                            </li>
                        </ul>
                    ) : null}
                    {buyerSortOpen ? (
                        <ul className="menu fortySixPercentMenu" ref={buyerSortRef}>
                            {[
                                { name: 'Sort By', sort: 'sort_one', direction: 'sort_one_direction' },
                                { name: 'Then By', sort: 'sort_two', direction: 'sort_two_direction' },
                                { name: 'Then By', sort: 'sort_three', direction: 'sort_three_direction' },
                                { name: 'Then By', sort: 'sort_four', direction: 'sort_four_direction' },
                                { name: 'Then By', sort: 'sort_five', direction: 'sort_five_direction' },
                                { name: 'Then By', sort: 'sort_six', direction: 'sort_six_direction' },
                                { name: 'Then By', sort: 'sort_seven', direction: 'sort_seven_direction' },
                                { name: 'Then By', sort: 'sort_eight', direction: 'sort_eight_direction' },
                                { name: 'Then By', sort: 'sort_nine', direction: 'sort_nine_direction' },
                                { name: 'Then By', sort: 'sort_ten', direction: 'sort_ten_direction' },
                                { name: 'Then By', sort: 'sort_eleven', direction: 'sort_eleven_direction' },
                                { name: 'Then By', sort: 'sort_twelve', direction: 'sort_sort_twelve_direction' },
                            ].map((config, index) => (
                                <SortingOption
                                    key={index}
                                    name={config.name}
                                    sortArray={buyer_sort_array}
                                    defaultSort={buyerListSorting[config.sort]}
                                    defaultDirection={buyerListSorting[config.direction]}
                                    onSortChange={(e) => setBuyerListSorting({ ...buyerListSorting, [config.sort]: e.target.value })}
                                    onDirectionChange={(e) => setBuyerListSorting({ ...buyerListSorting, [config.direction]: e.target.value })}
                                />
                            ))}

                            <li>
                                <button onClick={() => handleBuyersFetch()}>Apply</button>
                            </li>
                        </ul>
                    ) : null}
                </div>
                <table className="sellerListTable">
                    <thead>
                    <tr className="outline">
                        <th></th>
                        <th className="outline">Seller Req ID</th>
                        <th className="outline">Buyer Req ID</th>
                        <th className="outline">Priority</th>
                        <th className="outline">Category</th>
                        <th className="outline">Request Name</th>
                        <th className="outline">Request Details</th>
                        <th className="outline">{DATE_RANGE}</th>
                        <th className="outline">{BUYER_PREFERRED_FILE_TYPE}</th>
                        <th className="outline">Requested Date</th>
                        <th className="whiteHeader"></th>
                    </tr>
                    </thead>
                    <tbody>
                    { buyerRowData.length > 0 && (buyerRowData).map((buyerRequest,i) =>{
                        const showNewRequestButton = buyerRequest.seller_request_id === '' ? true : false;
                        return (
                            <tr key={buyerRequest.id} className="outline">
                                <td className="outline">
                                    <input type="checkbox"
                                           onClick={(event) => {
                                               let id = buyerRequest.id;
                                               if (event.target.checked) {
                                                   const newArray = [...dataRequest, id];
                                                   setDataRequest(newArray);
                                               } else {
                                                   const filteredArray = dataRequest.filter(item => item !== id)
                                                   setDataRequest(filteredArray);
                                               }
                                           }}
                                    />
                                </td>
                                <td className="outline">{buyerRequest.seller_request_id}</td>
                                <td className="outline">{buyerRequest.buyer_request_list_id}</td>
                                <td className="outline">{buyerRequest.priority}</td>
                                <td className="outline">{buyerRequest.category}</td>
                                <td className="outline">{buyerRequest.request_name}</td>
                                <td className="outline">{buyerRequest.details}</td>
                                <td className="outline">{buyerRequest.date_range}</td>
                                <td className="outline">{buyerRequest.file_type}</td>
                                <td className="outline">{buyerRequest.requested_date}</td>
                                <td className="outline">
                                    {showNewRequestButton &&
                                        <button className="grayButton" onClick={() => createNewSellerRequest(buyerRequest.id)}>
                                            New Seller Request
                                        </button>}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <button onClick={() => setLinkConfirmationModalStatus(true)} className="linkButton">Link</button>
                <h1>Seller Request List</h1>
                {error && <h1 className="userError">{error}</h1>}
                <UpdateSellerRequestPriority
                    showModal={updatePriorityModal}
                    setShowModal={showUpdatePriorityModal}
                    sellerRequest={sellerRequest}
                    sellerRequestId={sellerRequestId}
                    setUpdateSellerList={setUpdateSellerListData}
                />
                <div>
                    <button className="grayButton" onClick={() => setSellerFilterOpen(!sellerFilterOpen)}>Filter</button>
                    {sellerFiltered && <button className="grayButton" onClick={()=> clearSellerFilter()}>Clear Filter</button>}
                    {!sellerFiltered && <button className="lightGrayButton" onClick={()=> clearSellerFilter()}>Clear Filter</button>}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <button className="grayButton" onClick={() => setSellerSortOpen(!sellerSortOpen)}>Sort</button>
                    {sellerSorted && <button className="grayButton" onClick={()=> clearSellerSort()}>Clear Sort</button>}
                    {!sellerSorted && <button className="lightGrayButton" onClick={()=> clearSellerSort()}>Clear Sort</button>}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <SellerListFilter filterOpen={sellerFilterOpen} setSellerFilteredData={setSellerFilteredData}
                                      sellerFilteredData={sellerFilteredData} handleSellersListFetch={handleSellersFetch}
                                      filterRef={sellerFilterRef}
                    />
                    {sellerSortOpen ? (
                        <ul className="menu fiftyOnePercentMenu" ref={sellerSortRef}>
                            {[
                                { name: 'Sort By', sort: 'sort_one', direction: 'sort_one_direction' },
                                { name: 'Then By', sort: 'sort_two', direction: 'sort_two_direction' },
                                { name: 'Then By', sort: 'sort_three', direction: 'sort_three_direction' },
                                { name: 'Then By', sort: 'sort_four', direction: 'sort_four_direction' },
                                { name: 'Then By', sort: 'sort_five', direction: 'sort_five_direction' },
                                { name: 'Then By', sort: 'sort_six', direction: 'sort_six_direction' },
                                { name: 'Then By', sort: 'sort_seven', direction: 'sort_seven_direction' },
                                { name: 'Then By', sort: 'sort_eight', direction: 'sort_eight_direction' },
                                { name: 'Then By', sort: 'sort_nine', direction: 'sort_nine_direction' },
                                { name: 'Then By', sort: 'sort_ten', direction: 'sort_ten_direction' },
                            ].map((config, index) => (
                                <SortingOption
                                    key={index}
                                    name={config.name}
                                    sortArray={seller_sort_array}
                                    defaultSort={sellerListSorting[config.sort]}
                                    defaultDirection={sellerListSorting[config.direction]}
                                    onSortChange={(e) => setSellerListSorting({ ...sellerListSorting, [config.sort]: e.target.value })}
                                    onDirectionChange={(e) => setSellerListSorting({ ...sellerListSorting, [config.direction]: e.target.value })}
                                />
                            ))}
                            <li>
                                <button onClick={handleSellersFetch}>Apply</button>
                            </li>
                        </ul>
                    ) : null}

                </div>
                <table className="sellerListTable">
                    <thead>
                    <tr className="outline">
                        <th className="outline"></th>
                        <th className="outline">Phase</th>
                        <th className="outline">Priority</th>
                        <th className="outline">Requested On</th>
                        <th className="outline">Seller Req ID</th>
                        <th className="outline">Buyer Req ID</th>
                        <th className="outline">Category</th>
                        <th className="outline">Request Name</th>
                        <th className="outline">Request Details</th>
                        <th className="outline">{DATE_RANGE}</th>
                        <th className="outline">Preferred File Type</th>
                        <th className="outline">Status</th>
                        <th className="outline">Responses</th>
                        <th className="outline"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {sellerListRowData && sellerListRowData.map((sellerListData,i)=>{
                        const showReopen = sellerListData.status === "Closed";
                        const showEdit = sellerListData.show_edit_button;

                        return (
                            <tr key={sellerListData.id} className="outline">
                                <td className="outline">
                                    <input type="checkbox"
                                           disabled={disableCheckboxes && !boxes[sellerListData.id]}
                                           onChange={handleChange}
                                           id={sellerListData.id}
                                           onClick={(event) => {
                                               let id = sellerListData.id;
                                               if (event.target.checked) {
                                                   const newValue = id
                                                   setSellerRequestId(newValue);
                                                   setDisableCheckboxes(true);
                                               } else {
                                                   setSellerRequestId();
                                                   setDisableCheckboxes(false);
                                               }
                                           }}
                                    />
                                </td>
                                <td className="outline">{sellerListData.batch}</td>
                                <td className="outline">{PRIORITY[sellerListData.priority]}</td>
                                <td className="outline">{sellerListData.requested_date}</td>
                                <td className="outline">{sellerListData.seller_request_id}</td>
                                <td className="outline">{sellerListData.buyer_request_id}</td>
                                <td className="outline">{sellerListData.category}</td>
                                <td className="outline">{sellerListData.data_requested}</td>
                                <td className="outline">{sellerListData.details}</td>
                                <td className="outline">{sellerListData.parameters}</td>
                                <td className="outline">{sellerListData.file_format}</td>
                                <td className="outline">{sellerListData.status}</td>
                                {sellerListData.response_count == 1 && <td className="outline">
                                    <button className="grayButton"  onClick={() => { openResponseModal(sellerListData.id, '', "POST", sellerListData) }}>
                                        View {sellerListData.response_count} Response
                                    </button>
                                </td>}
                                {sellerListData.response_count > 1 && <td className="outline">
                                    <button className="grayButton" onClick={() => { openResponseModal(sellerListData.id, '', "POST", sellerListData) }}>
                                        View {sellerListData.response_count} Responses
                                    </button>
                                </td>}
                                {sellerListData.response_count === 0 && <td className="outline"></td>}
                                <td>
                                    <button className="grayButton" onClick={() => { openResponseModal(sellerListData.id, '', "POST", sellerListData) }}>{RESPOND_BUTTON_TEXT}</button>
                                    {showReopen && <button className="grayButton"  onClick={() => { callUpdateSellerList(sellerListData.id)}}>Reopen</button>}
                                    <button className="grayButton"  onClick={() => { callAdjustPriority(sellerListData.id)}}>Adjust Priority</button>
                                    {showEdit && <button className="grayButton"  onClick={() => { updateSellerRequest(sellerListData) }}>Edit</button>}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </>}
        </div>
    );
}