import Modal from "react-modal";
import {customStyles} from "../../Constants";
import {React, useEffect, useRef, useState} from "react";
import {fetchSellerRequest, saveNewDuplicateQueue} from "../../Functions/SellersList";
import {useForm} from "react-hook-form";
import fetchCategories from "../../Functions/CategoryFunctions";
import SellerListFilter from "../Filters/SellerFilter";


function DuplicateSellerRequest({projectId, sellerRequestId, modalIsOpen, setModalIsOpen, fetchSellerList,
                                    sellerPageFilteredData, setsellerListRowData, setSellerPageFilterOpen,
                                    setSellerSortOpen}) {
    const { register, handleSubmit, reset , setValue} = useForm();
    const[boxes, setBoxes] = useState({});
    const[categories, setCategories] = useState([]);
    const[disableCheckboxes, setDisableCheckboxes] = useState(false);
    const[error, setError] = useState(false);
    const[filterOpen, setFilterOpen] = useState(false);
    const[filtered, setFiltered] = useState(false);
    const[sellerFilteredData, setSellerFilteredData] = useState({
        id: "",
        assigned_date: "",
        seller_request_id: "",
        deliverable_phase: "",
        priority: "",
        data_requested: "",
        parameters: "",
        details: "",
        description: "",
        status_id: "",
        batch: "",
        category_id: "",
        batch_enabled: true,
        deleted: false,
    });
    const[sellerListSorting, setSellerListSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
        sort_five: "",
        sort_five_direction:"asc",
        sort_six: "",
        sort_six_direction:"asc",
        sort_seven: "",
        sort_seven_direction:"asc",
        sort_eight: "",
        sort_eight_direction:"asc",
        sort_nine: "",
        sort_nine_direction:"asc",
    });
    const sort_array = [
        {id: "seller_request_id", name: "Request ID"},
        {id: "category.category_name", name: "Category"},
        {id: "batch", name: "Phase"},
        {id: "assigned_date", name: "Requested On"},
        {id: "data_requested", name: "Request Name"},
        {id: "parameters", name: "Date Range"},
        {id: "details", name: "Request Details"},
        {id: "file_type.file_type", name: "Preferred File Type"},
        {id: "status.status", name: "Status"},
    ];
    const[sellerRequest, setSellerRequest] = useState();
    const[sellerRequests, setSellerRequests] = useState([]);
    const[sorted, setSorted] = useState(false);
    const[sortOpen, setSortOpen] = useState();
    const[retainedId, setRetainedId] = useState();
    const[updateSellerList, setUpdateSellerList] = useState();
    const filterRef = useRef(null);
    const sortRef = useRef(null);

    const handleClickOutside = event => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            // Close the modal here
            setFilterOpen(false);
        }
        if (sortRef.current && !sortRef.current.contains(event.target)) {
            // Close the modal here
            setSortOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleSellersListFetch = (e) => {
        setError(null);
        for (const key in sellerFilteredData) {
            if (key == 'batch_enabled' || key == 'deleted') {
                continue;
            }
            if (sellerFilteredData[key] !== "") {
                setFiltered(true);
                break;
            }
        }

        for (const key in sellerListSorting) {
            if (sellerListSorting[key] !== "" && sellerListSorting[key] !== 'asc') {
                setSorted(true);
                break;
            }
        }
        fetchSellerList(projectId, sellerFilteredData, setSellerRequests, setFilterOpen, sellerListSorting, setSortOpen);
        reset();
    }

    useEffect( () => { fetchSellerRequest(sellerRequestId, setSellerRequest, setFilterOpen); }, [sellerRequestId]);
    useEffect( () => { fetchCategories(setCategories); }, [sellerRequestId]);
    useEffect( () => { handleSellersListFetch() }, [updateSellerList]);

    function clearSellerFilter() {
        const emptyFilterData = Object.keys(sellerFilteredData).reduce((acc, key) => {
            if (key === "batch_enabled") {
                acc[key] = true;
            } else if (key === "deleted") {
                acc[key] = false;
            } else {
                acc[key] = "";
            }
            return acc;
        }, {});
        setSellerFilteredData(emptyFilterData);
        setFiltered(false);
        handleSellersListFetch();
        setUpdateSellerList(true);
    }

    function clearSort() {
        const emptySortData = Object.keys(sellerListSorting).reduce((acc, key) => {
            if (key.endsWith('_direction')) {
                acc[key] = 'asc';
            } else {
                acc[key] = '';
            }
            return acc;
        }, {});
        setSellerListSorting(emptySortData);
        setSorted(false);
        handleSellersListFetch();
        setUpdateSellerList(true);
    }

    function handleChange(e) {
        const {
            target: { id, checked }
        } = e;

        setBoxes({ ...boxes, [id]: checked });
    }

    function handleClose() {
        setModalIsOpen(false);
        document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
    }

    function saveDupeRequest(data) {
        const deleted_seller_request_id = data.id;
        const checkboxes = data.checkbox;

        if (typeof checkboxes !== "string") {
            checkboxes.map((checkbox)=> {
                const dupe_data = {
                    "project_id": parseInt(projectId),
                    "retained_seller_request_id": parseInt(checkbox),
                    "deleted_seller_request_id": parseInt(deleted_seller_request_id),
                    "status": "Open"
                }
                saveNewDuplicateQueue(dupe_data, setModalIsOpen, reset, fetchSellerList, sellerPageFilteredData,
                    setsellerListRowData, setSellerPageFilterOpen, sellerListSorting, setSellerSortOpen);
            })
        } else {
            const dupe_data = {
                "project_id": parseInt(projectId),
                "retained_seller_request_id": parseInt(data.checkbox[0]),
                "deleted_seller_request_id": parseInt(deleted_seller_request_id),
                "status": "Open"
            }
            saveNewDuplicateQueue(dupe_data, setModalIsOpen, reset, fetchSellerList, sellerPageFilteredData,
                setsellerListRowData, setSellerPageFilterOpen, sellerListSorting, setSellerSortOpen);
        }
    }

    return <>
        <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
            <h2>Report Duplicate</h2>
            <button className="grayButton" onClick={() => handleClose()}>Close</button>
            <br />
            <br />
            <b>To delete request {sellerRequest && sellerRequest.seller_request_id} as a duplicate, please select the
                pre-existing request in the lower grid.</b>
            <br />
            <br />
            <b>Request to be Deleted</b>
            <table className="sellerListTable">
                <thead>
                    <tr className="outline">
                        <th></th>
                        <th className="outline">Phase</th>
                        <th className="outline">Requested On</th>
                        <th className="outline">Seller Req ID</th>
                        <th className="outline">Category</th>
                        <th className="outline">Request Name</th>
                        <th className="outline">Request Details</th>
                        <th className="outline">Date Range</th>
                        <th className="outline">Preferred File Type</th>
                        <th className="outline">Status</th>
                    </tr>
                </thead>
                <tbody>
                {sellerRequest &&
                    <tr key={sellerRequest.id} className="outline">
                        <td><input type="checkbox" disabled checked/></td>
                        <td className="outline">{sellerRequest && sellerRequest.batch}</td>
                        <td className="outline">{sellerRequest && sellerRequest.assigned_date}</td>
                        <td className="outline">{sellerRequest && sellerRequest.seller_request_id}</td>
                        <td className="outline">{sellerRequest && sellerRequest.category}</td>
                        <td className="outline">{sellerRequest && sellerRequest.data_requested}</td>
                        <td className="outline">{sellerRequest && sellerRequest.details}</td>
                        <td className="outline">{sellerRequest && sellerRequest.parameters}</td>
                        <td className="outline">{sellerRequest && sellerRequest.file_format}</td>
                        <td className="outline">{sellerRequest && sellerRequest.status}</td>
                    </tr>}
                </tbody>
            </table>
            <br />
            <br />
            <b>Request to be Retained</b>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            <button className="grayButton" onClick={() => setFilterOpen(!filterOpen)}>Filter</button>
            {filtered && <button className="grayButton" onClick={()=>clearSellerFilter()}>Clear Filter</button>}
            {!filtered && <button className="lightGrayButton" onClick={()=>clearSellerFilter()}>Clear Filter</button>}

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button className="grayButton" onClick={() => setSortOpen(!sortOpen)}>Sort</button>
            {sorted && <button className="grayButton" onClick={()=>clearSort()}>Clear Sort</button>}
            {!sorted && <button className="lightGrayButton" onClick={()=>clearSort()}>Clear Sort</button>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <SellerListFilter filterOpen={filterOpen} setSellerFilteredData={setSellerFilteredData}
                              sellerFilteredData={sellerFilteredData} handleSellersListFetch={handleSellersListFetch}
                              filterRef={filterRef}
            />
            {sortOpen ? (
                <ul className="menu fiftyOnePercentMenu" ref={sortRef}>
                    <li className="menu-item">
                        Sort By
                        <select name="id" defaultValue={sellerListSorting.sort_one}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_one: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>

                        <select name="sort_one_direction" defaultValue={sellerListSorting.sort_one_direction}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_one_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_two" defaultValue={sellerListSorting.sort_two}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_two: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_two_direction" defaultValue={sellerListSorting.sort_two_direction}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_two_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_three" defaultValue={sellerListSorting.sort_three}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_three: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_three_direction" defaultValue={sellerListSorting.sort_three_direction}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_three_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_four" defaultValue={sellerListSorting.sort_four}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_four: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_four_direction" defaultValue={sellerListSorting.sort_four_direction}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_four_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_five" defaultValue={sellerListSorting.sort_five}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_five: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_five_direction" defaultValue={sellerListSorting.sort_five_direction}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_five_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_six" defaultValue={sellerListSorting.sort_six}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_six: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_six_direction" defaultValue={sellerListSorting.sort_six_direction}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_six_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_seven" defaultValue={sellerListSorting.sort_seven}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_seven: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_seven_direction" defaultValue={sellerListSorting.sort_seven_direction}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_seven_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_eight" defaultValue={sellerListSorting.sort_eight}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_eight: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_eight_direction" defaultValue={sellerListSorting.sort_eight_direction}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_eight_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_nine" defaultValue={sellerListSorting.sort_nine}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_nine: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_nine_direction" defaultValue={sellerListSorting.sort_nine_direction}
                                onChange={(e) =>
                                    setSellerListSorting({...sellerListSorting, sort_nine_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li>
                        <button onClick={handleSellersListFetch}>Apply</button>
                    </li>
                </ul>
            ) : null}

            <form className="sellerDuplicateForm" onSubmit={handleSubmit(data =>
                saveDupeRequest(data))
            }>
                <input type="submit" value="Report Duplicate" className="reportDuplicateButton"/>
                {setValue('id', sellerRequestId)}
                <input name="seller_request_id" {...register('seller_request_id')} hidden/>
                <input name="project_id" {...register('project_id')} hidden/>
                <table className='sellerListTable'>
                    <thead>
                    <tr className="outline">
                        <th className="outline"></th>
                        <th className="outline">Phase</th>
                        <th className="outline">Requested On</th>
                        <th className="outline">Seller Req ID</th>
                        <th className="outline">Category</th>
                        <th className="outline">Request Name</th>
                        <th className="outline">Request Details</th>
                        <th className="outline">Date Range</th>
                        <th className="outline">Preferred File Type</th>
                        <th className="outline">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                {sellerRequests.length > 0  && sellerRequests.filter(function(request) {
                    if (request.id === sellerRequestId) {
                        return false;
                    }
                    return true;
                }).map((request,i)=> {
                    return (
                        <>
                        <tr key={request.id} className="outline">
                            <td className="outline">
                                <input type="checkbox" {...register('checkbox')}
                                       value={request.id}
                                       disabled={disableCheckboxes && !boxes[request.id]}
                                       onChange={handleChange}
                                       id={request.id}
                                       onClick={(event) => {
                                           if (event.target.checked) {
                                               setRetainedId(request.id);
                                               setDisableCheckboxes(true);
                                           } else {
                                               setRetainedId();
                                               setDisableCheckboxes(false);
                                           }
                                       }}
                                />
                            </td>
                            <td className="outline">{request && request.batch}</td>
                            <td className="outline">{request && request.assigned_date}</td>
                            <td className="outline">{request && request.seller_request_id}</td>
                            <td className="outline">{request && request.category}</td>
                            <td className="outline">{request && request.data_requested}</td>
                            <td className="outline">{request && request.details}</td>
                            <td className="outline">{request && request.parameters}</td>
                            <td className="outline">{request && request.file_format}</td>
                            <td className="outline">{request && request.status}</td>
                        </tr>
                        </>
                    )
                })}
                    </tbody>
                </table>
            </form>
        </Modal>
    </>
}

export  default DuplicateSellerRequest;