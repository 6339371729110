export const SortingOption = ({ name, sortArray, defaultSort, defaultDirection, onSortChange, onDirectionChange }) => (
    <li className="menu-item">
        {name}
        <select name="sort" defaultValue={defaultSort} onChange={onSortChange}>
            <option value={0}>Sort Column</option>
            {sortArray.map((sort) => (
                <option key={sort.id} value={sort.id}>{sort.name}</option>
            ))}
        </select>
        <select name="sort_direction" defaultValue={defaultDirection} onChange={onDirectionChange}>
            <option value="asc">Ascending</option>
            <option value="desc">Descending</option>
        </select>
    </li>
);
