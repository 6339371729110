import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import './navigation.css';
import { Link, Routes, Route, useLocation, Outlet } from 'react-router-dom';
import Auditlog from "../Auditlog/Auditlog";
import DataRequestManagement from "../DataRequestManagement/DataRequestManagement";
import FileLog from "../FileLog/FileLog";
import DuplicateQueue from "../DuplicateQueue/DuplicateQueue";
import BuyersList from "../BuyersList/BuyersList";
import EntityManagement from "../EntityManagement/EntityManagement";
import Profile from "../Profile/Profile";
import Projects from "../Projects/Projects";
import ResponseManagement from "../ResponseManagement/ResponseManagement";
import Roles from "../Role/Roles";
import SellerList from "../SellerList/SellerList";
import ThirdPartyAssignment from "../ThirdPartyAssignment/ThirdPartyAssignment";
import User from "../User/User";
import { ADMIN, BUYER, FILE_LOG, SELLER } from '../../Constants';
import { parsePermissions } from "../../Functions/PermissionsFunctions";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import EmailQueue from "../EmailQueue/EmailQueue";

function BottomNav({
                       uploading,
                       adminPermission,
                       cantSeePermission,
                       buyerPermission,
                       sellerPermission,
                       handleLogout,
                       showLogoutModal,
                       confirmLogout,
                       cancelLogout
                   }) {
    const location = useLocation();
    const [value, setValue] = useState(location.pathname);

    useEffect(() => {
        setValue(location.pathname);
    }, [location.pathname]);

    return (
        <Box justifyContent="space-between" alignItems="center">
            {uploading && (
                <div style={{ backgroundColor: "#3178C6", margin: "0px" }}>
                    <h1 style={{ color: "white", margin: "0px" }} className="blink_me">
                        &nbsp;Files Uploading&nbsp;
                    </h1>
                </div>
            )}
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                <BottomNavigationAction component={Link} label="Home" to="/" value="/" />
                {adminPermission && (
                    <BottomNavigationAction
                        component={Link}
                        label="Projects"
                        to="/projectCreation"
                        value="/projectCreation"
                        className={location.pathname === '/projectCreation' ? 'active' : ''}
                    />
                )}
                {adminPermission && (
                    <BottomNavigationAction
                        component={Link}
                        label="Entities"
                        to="/entity"
                        value="/entity"
                        className={location.pathname === '/entity' ? 'active' : ''}
                    />
                )}
                {cantSeePermission && (
                    <BottomNavigationAction
                        component={Link}
                        label="Roles/Permissions"
                        to="/rolesManagement"
                        value="/rolesManagement"
                        className={location.pathname === '/rolesManagement' ? 'active' : ''}
                    />
                )}
                {adminPermission && (
                    <BottomNavigationAction
                        component={Link}
                        label="Users"
                        to="/user"
                        value="/user"
                        className={location.pathname === '/user' ? 'active' : ''}
                    />
                )}
                {adminPermission && (
                    <BottomNavigationAction
                        component={Link}
                        label="Third Party Assignment"
                        to="/thirdPartyAssignment"
                        value="/thirdPartyAssignment"
                        className={location.pathname === '/thirdPartyAssignment' ? 'active' : ''}
                    />
                )}
                {adminPermission && (
                    <BottomNavigationAction
                        component={Link}
                        label="Request Management"
                        to="/dmo"
                        value="/dmo"
                        className={location.pathname === '/dmo' ? 'active' : ''}
                    />
                )}
                {adminPermission && (
                    <BottomNavigationAction
                        component={Link}
                        label="Response Queue"
                        to="/responseManagement"
                        value="/responseManagement"
                        className={location.pathname === '/responseManagement' ? 'active' : ''}
                    />
                )}
                {adminPermission && (
                    <BottomNavigationAction
                        component={Link}
                        label="Duplicate Queue"
                        to="/seller_duplicates"
                        value="/seller_duplicates"
                        className={location.pathname === '/seller_duplicates' ? 'active' : ''}
                    />
                )}
                {buyerPermission && (
                    <BottomNavigationAction
                        component={Link}
                        label="Buyer Requests"
                        to="/buyersListManagement"
                        value="/buyersListManagement"
                        className={location.pathname === '/buyersListManagement' ? 'active' : ''}
                    />
                )}
                {sellerPermission && (
                    <BottomNavigationAction
                        component={Link}
                        label="Seller Requests"
                        to="/sellerList"
                        value="/sellerList"
                        className={location.pathname === '/sellerList' ? 'active' : ''}
                    />
                )}
                {buyerPermission && (
                    <BottomNavigationAction
                        component={Link}
                        label={FILE_LOG}
                        to="/filelog"
                        value="/filelog"
                        className={location.pathname === '/filelog' ? 'active' : ''}
                    />
                )}
                {adminPermission && (
                    <BottomNavigationAction
                        component={Link}
                        label="Audit Log"
                        to="/auditlog"
                        value="/auditlog"
                        className={location.pathname === '/auditlog' ? 'active' : ''}
                    />
                )}
                {adminPermission && (
                    <BottomNavigationAction
                        component={Link}
                        label="Email Queue"
                        to="/emailQueue"
                        value="/emailQueue"
                        className={location.pathname === '/emailQueue' ? 'active' : ''}
                    />
                )}
                <BottomNavigationAction component="button" label="Logout" onClick={handleLogout} />
            </BottomNavigation>
            <Outlet />
            {showLogoutModal && (
                <ConfirmationModal
                    message="Are you sure you want to logout?"
                    onConfirm={confirmLogout}
                    onCancel={cancelLogout}
                />
            )}
        </Box>
    );
}

export default function Navigation() {
    const [buyerPermission, setBuyerPermission] = useState(false);
    const [adminPermission, setAdminPermission] = useState(false);
    const [sellerPermission, setSellerPermission] = useState(false);
    const [cantSeePermission] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [uploading, setUploading] = useState(false);

    window.addEventListener('storage', () => {
        const localStorageValue = localStorage.getItem('documentsUploading');
        setUploading(localStorageValue > 0);
    });

    const handleLogout = () => {
        setShowLogoutModal(true);
    };

    const cancelLogout = () => {
        setShowLogoutModal(false);
    };

    const confirmLogout = () => {
        setShowLogoutModal(false);
        localStorage.removeItem('projectId');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('permission');
        window.location.href = '/';
    };

    useEffect(() => {
        setAdminPermission(false);
        setSellerPermission(false);
        setBuyerPermission(false);
        parsePermissions(setBuyerPermission, setSellerPermission, setAdminPermission);
    }, [BUYER, SELLER, ADMIN]);

    return (
        <Routes>
            <Route path="/" element={
                <BottomNav
                    uploading={uploading}
                    adminPermission={adminPermission}
                    cantSeePermission={cantSeePermission}
                    buyerPermission={buyerPermission}
                    sellerPermission={sellerPermission}
                    handleLogout={handleLogout}
                    showLogoutModal={showLogoutModal}
                    confirmLogout={confirmLogout}
                    cancelLogout={cancelLogout}
                />
            }>
                <Route index element={<Profile />} />
                <Route path="buyersListManagement" element={<BuyersList />} />
                <Route path="dmo" element={<DataRequestManagement />} />
                <Route path="entity" element={<EntityManagement />} />
                <Route path="projectCreation" element={<Projects />} />
                <Route path="sellerList" element={<SellerList />} />
                <Route path="user" element={<User />} />
                <Route path="thirdPartyAssignment" element={<ThirdPartyAssignment />} />
                <Route path="rolesManagement" element={<Roles />} />
                <Route path="responseManagement" element={<ResponseManagement />} />
                <Route path="filelog" element={<FileLog />} />
                <Route path="seller_duplicates" element={<DuplicateQueue />} />
                <Route path="auditlog" element={<Auditlog />} />
                <Route path="emailQueue" element={<EmailQueue />} />
            </Route>
        </Routes>
    );
}
